<template>
	<section class="w-15-percent-cl-2 w-15-percent-cl-3 w-15-percent-cl-5 w-20-percent-cl-4">
		<b-table small :fields="fields_activity" :items="items_activity" responsive="lg" tbody-tr-class="size-14 table-row cursor-pointer" show-empty class='mb-3' @head-clicked="headClicked" @row-clicked="rowViewAction">
			<template #head(id)="data">
		        <span>{{ data.label }}</span>
		        <span :class="['cursor-pointer iconsort', {sort: data.field.key == sort.field && sort.type == 'desc'}]" style="position: relative; left: 16px;">
		        	<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
						<path d="M9 10.4996L11.5 12.9996L13.9999 10.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M11.5 6.99963V12.9996" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 7.99963H7.49994" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 3.99963H11.4999" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						<path d="M3 11.9996H6.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
					</svg>
		        </span>
		    </template>
			<template #cell(id)="data">					
				<span v-if="data.field.key == sort.field && sort.type == 'desc'">
					<template v-if="selected == 10">
						{{ ((total - data.index) - ((currentPage - 1) * 10 )) }}
					</template>
					<template v-else-if="selected == 20">
						{{ ((total - data.index) - ((currentPage - 1) * 20 )) }}
					</template>
					<template v-else>
						{{ ((total - data.index) - ((currentPage - 1) * 30 )) }}
					</template>
				</span>
				<span v-else>
					<template v-if="selected == 10">
						{{ ((currentPage - 1) * 10) + (data.index + 1) }}
					</template>
					<template v-else-if="selected == 20">
						{{ ((currentPage - 1) * 20) + (data.index + 1) }}
					</template>
					<template v-else>
						{{ ((currentPage - 1) * 30) + (data.index + 1) }}
					</template>
				</span>
			</template>	
    		<template #cell(description)="data">
				<span class="text-center maxThreeRow mx-auto">{{ data.item.description }}</span>
			</template>	
			<template #cell(action)="data">
				<div class="d-block text-center">
					<span class="px-05 cursor-pointer" @click.stop="eyeAction(data.item.project_country,data.item.project_id,data.item.activity_id)">
						<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
							<path d="M12 5.25C4.5 5.25 1.5 12.0007 1.5 12.0007C1.5 12.0007 4.5 18.75 12 18.75C19.5 18.75 22.5 12.0007 22.5 12.0007C22.5 12.0007 19.5 5.25 12 5.25Z" stroke="#8471FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
							<path d="M12 15.75C14.0711 15.75 15.75 14.0711 15.75 12C15.75 9.92893 14.0711 8.25 12 8.25C9.92893 8.25 8.25 9.92893 8.25 12C8.25 14.0711 9.92893 15.75 12 15.75Z" stroke="#8471FF" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
						</svg>
					</span>										
				</div>										
			</template>		
			<template #head()="data">
				<span class="d-block text-center">{{ data.label }}</span>
			</template>	
			<template #head(activity_name)="data">
				<span>{{ data.label }}</span>
			</template>
			<template #cell(activity_name)="data">
				<span class="maxThreeRow">{{ data.item.activity_name }}</span>
			</template>
			<template #cell(project_name)="data">
				<span  class="text-center-row">{{ data.item.project_name }}</span>
			</template>
			<template #cell()="data">
				<span class="text-center maxThreeRow">{{ data.value }}</span>
			</template>
    		<template #empty="scope">
		      	<h4 class="my-3 text-center" style="color: #CFCFCF !important; font-size: 14px !important">No data</h4>
		    </template>
    	</b-table>
    	<div v-if="this.total > 0" class="paging-cus relative">
			<b-pagination
		      	v-model="currentPage"
		      	:total-rows="total"
		      	:per-page="perPage"
		      	first-number
	        	last-number
	        	class="just-center"
		    ></b-pagination>
		    <div class="limit-per">
				<select v-model="selected" class="cursor-pointer">
				    <option v-for="limit in limitpage">{{ limit }}</option>
				</select>			  
		    </div>
		</div>
	</section>
</template>
<script>

    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

	export default {
	  	data() {
		    return {
            	fields_activity: [
            		{ key: 'id', label: 'No.' },
			        { key: 'activity_name', label: 'Activity Listing' },
			        { key: 'project_name', label: 'Project Name' },
			        { key: 'activity_description', label: 'Description' },
			        { key: 'location', label: 'Location' },
			        { key: 'activity_status', label: 'Activity Status' },
			        { key: 'action', label: 'Actions' },
            	],
            	items_activity: [],

            	sort: {"field": "id","type": "asc"},
            	perPage: 10,
        		currentPage: 1, 
        		total: 0,
        		limitpage: ['10', '20', '30'],
    			selected: '10'
            }
		},	
		mounted () {
		    this.selected = '10'
		},
		watch: {
			currentPage(val){
				this.fetchList()
			},
			selected(val){
			if(val) {
				this.perPage =val
				this.fetchList()
			}
			}
		},
		created(){
			this.fetchList()
		},
		methods: {
			fetchList(){
	            this.$store
	            .dispatch('project/listActivitiesPart', {
	              	id: this.$route.params.id,
	              	limit: this.perPage,
					page: this.currentPage, 	
					sort: this.sort              	
	            })
	            .then(response => {
	              	this.items_activity = response.data.data.data
	              	this.total = response.data.data.total
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
	        eyeAction(country_id, project_id, id){
	        	this.$router.push(`/admin/our-portfolio/project/${country_id}/edit/${project_id}/activity/${id}`)
	        },
			rowViewAction(item){
				this.$router.push(`/admin/our-portfolio/project/${item.project_country}/edit/${item.project_id}/activity/${item.activity_id}`)
			},
	        headClicked(key) {
				if(this.sort.field == key) {
					if(this.sort.type == 'asc') this.sort.type = 'desc'
					else this.sort.type = 'asc'
				} else {					
					this.sort.field = key
					this.sort.type = 'desc'
				}
				this.fetchList()
			},
		},
	};	

</script>

<style lang="scss">
	@import "@/assets/scss/_ite_tab_table.scss";
	.text-center-row{
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		text-align: center !important;
	}
</style>