var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',[_c('b-row',{staticClass:"mb-2"},[_c('b-col',[_c('Breadcrumb',{attrs:{"labels":_vm.labels}})],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('div',{staticClass:"relative"},[_c('b-tabs',{staticClass:"caption-add table-custom",attrs:{"card":""}},[_c('b-tab',{staticClass:"overview-tab",attrs:{"title":"Basic Info","active":""}},[_c('b-form',{staticClass:"profile-frm mb-20 hasshadow",staticStyle:{"border-radius":"5px"},on:{"submit":function($event){$event.preventDefault();}}},[_c('b-row',{staticClass:"align-center"},[_c('b-col',[_c('div',{staticClass:"text-right mb-2"},[(_vm.$route.name == 'participant-edit')?[((_vm.userData && _vm.permission.edit))?[_c('b-button',{staticClass:"btn-df size-18 black",staticStyle:{"box-shadow":"0px 0px 4px rgba(0, 0, 0, 0.25)","margin-right":"10px"},attrs:{"type":"button","variant":"basic"},on:{"click":_vm.cancelAdd}},[_vm._v("Cancel")]),_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":_vm.saveAdd}},[_vm._v(" Save ")])]:[_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Edit ")])]]:[((_vm.userData && _vm.permission.edit))?[_c('b-button',{staticClass:"btn-df size-18 black",attrs:{"type":"button","variant":"primary"},on:{"click":function($event){return _vm.toEdit(_vm.detail.user_id)}}},[_vm._v(" Edit ")])]:[_c('b-button',{staticClass:"btn-df size-18 black btn-disabled-gray",attrs:{"type":"button","variant":"primary","disabled":""}},[_vm._v(" Edit ")])]]],2)])],1),_c('div',{staticClass:"pb-2 mb-10",staticStyle:{"border-top":"1px solid #000000B2"}}),_c('div',{staticClass:"frm-basic size-16 black-child d-block"},[_c('validation-observer',{ref:"form_rel",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var invalid = ref.invalid;
return [_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"PPID","label-cols-lg":"4"}},[_c('b-form-input',{attrs:{"placeholder":"PPID (Autofill)","readonly":"","disabled":""},model:{value:(_vm.detail.user_id),callback:function ($$v) {_vm.$set(_vm.detail, "user_id", $$v)},expression:"detail.user_id"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Status","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Status","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.selectStatus,"placeholder":"Select Status","disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.selectedStatus),callback:function ($$v) {_vm.selectedStatus=$$v},expression:"selectedStatus"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Salutation","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Salutation"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.salutation,"placeholder":"Select Salutation","disabled":_vm.isDisabled},model:{value:(_vm.selectedSalutation),callback:function ($$v) {_vm.selectedSalutation=$$v},expression:"selectedSalutation"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Contact No.","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Contact No."},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Contact No.","type":"number","formatter":_vm.length20,"disabled":_vm.isDisabled},on:{"keypress":_vm.validateNumber},model:{value:(_vm.detail.mobile),callback:function ($$v) {_vm.$set(_vm.detail, "mobile", $$v)},expression:"detail.mobile"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"First Name","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"First Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"First Name","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.detail.first_name),callback:function ($$v) {_vm.$set(_vm.detail, "first_name", $$v)},expression:"detail.first_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Last Name","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Last Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Last Name","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.detail.last_name),callback:function ($$v) {_vm.$set(_vm.detail, "last_name", $$v)},expression:"detail.last_name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Full Name","label-cols-lg":"2"}},[_c('b-form-input',{attrs:{"placeholder":"Full name","formatter":_vm.length50,"disabled":""},model:{value:(_vm.detail.full_name),callback:function ($$v) {_vm.$set(_vm.detail, "full_name", $$v)},expression:"detail.full_name"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Gender","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Gender"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"title","options":_vm.gender,"placeholder":"Select Gender","disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.selectedGender),callback:function ($$v) {_vm.selectedGender=$$v},expression:"selectedGender"}})]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Date of Birth","label-cols-lg":"4"}},[_c('div',{staticClass:"calendarIcon"},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"15","height":"15","viewBox":"0 0 24 24"}},[_c('path',{attrs:{"fill":"WindowText","d":"M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"}}),_c('path',{attrs:{"fill":"none","d":"M0 0h24v24H0z"}})])]),_c('flat-pickr',{staticClass:"form-control fieldCalendar",attrs:{"config":{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d', maxDate: _vm.maxBirthdayFun },"placeholder":"Select a date","disabled":_vm.isDisabled},model:{value:(_vm.detail.date_of_birth),callback:function ($$v) {_vm.$set(_vm.detail, "date_of_birth", $$v)},expression:"detail.date_of_birth"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Email","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Email","rules":"required|email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"type":"email","placeholder":"Email","disabled":_vm.isDisabled},model:{value:(_vm.detail.email),callback:function ($$v) {_vm.$set(_vm.detail, "email", $$v)},expression:"detail.email"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticStyle:{"border-top":"1px solid #B1B1B1","height":"20px"}})],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{attrs:{"label":"Mailing Country","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Mailing Country"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectCountry,"placeholder":"Select Country","disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.selectedCountry),callback:function ($$v) {_vm.selectedCountry=$$v},expression:"selectedCountry"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"star-required",attrs:{"label":"Organisation","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Organisation","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-select',{attrs:{"label":"name","options":_vm.selectOr,"placeholder":"Select Organisation","disabled":_vm.isDisabled,"id":_vm.disableField},model:{value:(_vm.selectedOr),callback:function ($$v) {_vm.selectedOr=$$v},expression:"selectedOr"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Designation/Position","label-cols-lg":"2","label-class":"pl-3"}},[_c('validation-provider',{attrs:{"name":"Designation/Position"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Designation","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.detail.position),callback:function ($$v) {_vm.$set(_vm.detail, "position", $$v)},expression:"detail.position"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Mailing Street","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Mailing Street"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Mailing Postal Code","formatter":_vm.length250,"disabled":_vm.isDisabled},model:{value:(_vm.detail.streets),callback:function ($$v) {_vm.$set(_vm.detail, "streets", $$v)},expression:"detail.streets"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Mailing Zip/Postal code","label-cols-lg":"2","label-class":"pl-3"}},[_c('validation-provider',{attrs:{"name":"Mailing Zip / Postal code"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Postal Code","formatter":_vm.length20,"disabled":_vm.isDisabled},model:{value:(_vm.detail.postalcode),callback:function ($$v) {_vm.$set(_vm.detail, "postalcode", $$v)},expression:"detail.postalcode"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Mailing Zip/Province","label-cols-lg":"2","label-class":"pl-3"}},[_c('validation-provider',{attrs:{"name":"Mailing Zip / Province"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Province","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.detail.state_province),callback:function ($$v) {_vm.$set(_vm.detail, "state_province", $$v)},expression:"detail.state_province"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{staticClass:"input-group-label",attrs:{"label":"Mailing City","label-cols-lg":"2"}},[_c('validation-provider',{attrs:{"name":"Mailing City"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"City","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.detail.city),callback:function ($$v) {_vm.$set(_vm.detail, "city", $$v)},expression:"detail.city"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('b-form-group',{attrs:{"label":"Remarks","label-cols-lg":"2"}},[_c('b-form-textarea',{attrs:{"placeholder":"Remarks","rows":"5","formatter":_vm.length1000,"disabled":_vm.isDisabled},model:{value:(_vm.detail.remarks),callback:function ($$v) {_vm.$set(_vm.detail, "remarks", $$v)},expression:"detail.remarks"}})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"12"}},[_c('div',{staticStyle:{"border-top":"1px solid #B1B1B1","height":"20px"}})])],1)]}}])}),_c('b-row',{staticStyle:{"width":"100%","margin":"0"}},[_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Password","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"rules":"required|min:12","name":"password","vid":"password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Password","type":"password","formatter":_vm.length50,"disabled":_vm.isDisabled},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-col',{staticClass:"px-0",attrs:{"lg":"6"}},[_c('b-form-group',{staticClass:"star-required input-group-label",attrs:{"label":"Re-type Password","label-cols-lg":"4"}},[_c('validation-provider',{attrs:{"name":"Re-type Password","rules":"required|confirmed:password"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"placeholder":"Retype Password","type":"password","disabled":_vm.isDisabled},model:{value:(_vm.form.re_pass),callback:function ($$v) {_vm.$set(_vm.form, "re_pass", $$v)},expression:"form.re_pass"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1)],1)],1)],1),_c('b-tab',{attrs:{"title":"Activities"}},[_c('Activities')],1)],1)],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }