<template>
	<section>
		<b-row class="mb-2">
		    <b-col>
				<Breadcrumb :labels="labels" />				
		    </b-col>		    
		</b-row>		
		<b-row>
		    <b-col md="12">
		    	<div class="relative">
			        <b-tabs card class="caption-add table-custom">                       
			            <b-tab title="Basic Info" active class="overview-tab">	
				                <b-form @submit.prevent class="profile-frm mb-20 hasshadow" style="border-radius: 5px">
				                	<b-row class="align-center">
							            <b-col>
							            	<div class="text-right mb-2">

							            		<template v-if="$route.name == 'participant-edit'">

								            		<template v-if="(userData && permission.edit)">

									                    <b-button type="button" variant="basic" class="btn-df size-18 black" @click="cancelAdd" style="box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); margin-right: 10px ">Cancel</b-button>

									                    <b-button type="button" variant="primary" class="btn-df size-18 black" @click="saveAdd" >
									                        Save
									                    </b-button>
									                    
										            </template>
										            <template v-else>
										            	<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                    Edit
										                </b-button>
										            </template>
										        </template>
										        <template v-else>
										        	<template v-if="(userData && permission.edit)">
										        		<b-button type="button" variant="primary" class="btn-df size-18 black"  @click="toEdit(detail.user_id)">
										                    Edit
										                </b-button>
										        	</template>
										        	<template v-else>
										        		<b-button type="button" variant="primary" class="btn-df size-18 black btn-disabled-gray" disabled>
										                    Edit
										                </b-button>
										        	</template>
										        </template>
							                </div>
							            </b-col>
							        </b-row>				                
					                <div style="border-top: 1px solid #000000B2;" class="pb-2 mb-10"></div>
					                <div class="frm-basic size-16 black-child d-block">         
					                	<validation-observer
								          	ref="form_rel"
								          	#default="{invalid}" >    
					                      	<b-row style="width: 100%; margin: 0;">   
					                          	<b-col lg="6" class="px-0">             
					                              	<b-form-group label="PPID" label-cols-lg="4" class="star-required input-group-label">
					                                    <b-form-input
					                                    	v-model="detail.user_id"
					                                      	placeholder="PPID (Autofill)"
					                                      	readonly
					                                      	disabled
					                                    ></b-form-input>
					                              	</b-form-group>
					                          	</b-col>	
					                          	<b-col lg="6" class="px-0">             
						                            <b-form-group label="Status" label-cols-lg="4" class="star-required">	
						                            	<validation-provider
							                                #default="{ errors }"
							                                name="Status"
							                                rules="required"
							                            >
							                                <v-select
							                                	v-model="selectedStatus"
							                                    label="title"
							                                    :options="selectStatus"
							                                    placeholder="Select Status"
							                                    :disabled="isDisabled"
                              									:id="disableField"
						                                    />
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
						                            </b-form-group>
					                          	</b-col> 	
					                          	<b-col lg="6" class="px-0">             
						                            <b-form-group label="Salutation" label-cols-lg="4" >	
						                            	<validation-provider
							                                #default="{ errors }"
							                                name="Salutation"
							                            >
							                                <v-select
							                                	v-model="selectedSalutation"
							                                    label="title"
							                                    :options="salutation"
							                                    placeholder="Select Salutation"
																:disabled="isDisabled"
						                                    />
						                                </validation-provider>
						                            </b-form-group>
					                          	</b-col> 	
					                          	<b-col lg="6" class="px-0">             
					                              	<b-form-group label="Contact No." label-cols-lg="4" class="input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Contact No."
							                            >
						                                    <b-form-input
						                                    	v-model="detail.mobile"
						                                      	placeholder="Contact No."
						                                      	type="number"
						                                      	:formatter="length20"
																@keypress="validateNumber"
																:disabled="isDisabled"
						                                    ></b-form-input>
															<small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="6" class="px-0">             
					                              	<b-form-group label="First Name" label-cols-lg="4" class="star-required input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="First Name"
							                                rules="required"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.first_name"
						                                      	placeholder="First Name"
						                                      	:formatter="length50"
																:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="6" class="px-0">             
					                              	<b-form-group label="Last Name" label-cols-lg="4" class="star-required input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Last Name"
							                                rules="required"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.last_name"
						                                      	placeholder="Last Name"
						                                      	:formatter="length50"
																:disabled="isDisabled">
																</b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Full Name" label-cols-lg="2" class="star-required input-group-label">
					                                    <b-form-input
					                                    	v-model="detail.full_name"
					                                      	placeholder="Full name"
					                                      	:formatter="length50"
															disabled
					                                    ></b-form-input>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="6" class="px-0">             
						                            <b-form-group label="Gender" label-cols-lg="4">	
						                            	<validation-provider
							                                #default="{ errors }"
							                                name="Gender"
							                            >
							                                <v-select
							                                	v-model="selectedGender"
							                                    label="title"
							                                    :options="gender"
							                                    placeholder="Select Gender"
							                                    :disabled="isDisabled"
                              									:id="disableField"
						                                    />
						                                </validation-provider>
						                            </b-form-group>
					                          	</b-col> 
					                          	<b-col lg="6" class="px-0">             
						                            <b-form-group label="Date of Birth" label-cols-lg="4">	
						                            	<div class="calendarIcon">
															<svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" viewBox="0 0 24 24"><path fill="WindowText" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/><path fill="none" d="M0 0h24v24H0z"/></svg>
														</div>
														<flat-pickr
															v-model="detail.date_of_birth"
															class="form-control fieldCalendar"           
															:config="{ altInput: true,altFormat: 'd/m/Y', dateFormat: 'Y-m-d', maxDate: maxBirthdayFun }"
															placeholder="Select a date"
															:disabled="isDisabled"
														/>
						                            </b-form-group>
					                          	</b-col> 
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Email" label-cols-lg="2" class="star-required input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Email"
							                                rules="required|email"     
							                            >
						                                    <b-form-input
						                                    	type = "email"
						                                    	v-model="detail.email"
						                                      	placeholder="Email"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                              	<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>
					                          	</b-col>
					                          	<b-col lg="6" class="px-0">             
						                            <b-form-group label="Mailing Country" label-cols-lg="4">
						                            	<validation-provider
							                                #default="{ errors }"
							                                name="Mailing Country"
							                            >
							                                <v-select
							                                	v-model="selectedCountry"
							                                    label="name"
							                                    :options="selectCountry"
							                                    placeholder="Select Country"
							                                    :disabled="isDisabled"
                              									:id="disableField"
						                                    />
															<small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
						                            </b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Organisation" label-cols-lg="2" class="star-required">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Organisation"
															rules="required"
							                            >
						                                    <v-select
						                                    	v-model="selectedOr"
							                                    label="name"
							                                    :options="selectOr"
							                                    placeholder="Select Organisation"
							                                    :disabled="isDisabled"
                              									:id="disableField"
						                                    />
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Designation/Position" label-cols-lg="2" class="input-group-label" label-class="pl-3">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Designation/Position"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.position"
						                                      	placeholder="Designation"
						                                      	:formatter="length50"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Mailing Street" label-cols-lg="2" class="input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Mailing Street"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.streets"
						                                      	placeholder="Mailing Postal Code"
						                                      	:formatter="length250"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Mailing Zip/Postal code" label-cols-lg="2" class="input-group-label" label-class="pl-3">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Mailing Zip / Postal code"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.postalcode"
						                                      	placeholder="Postal Code"
						                                      	:formatter="length20"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Mailing Zip/Province" label-cols-lg="2" class="input-group-label" label-class="pl-3">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Mailing Zip / Province"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.state_province"
						                                      	placeholder="Province"
						                                      	:formatter="length50"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Mailing City" label-cols-lg="2" class="input-group-label">
					                              		<validation-provider
							                                #default="{ errors }"
							                                name="Mailing City"
							                            >
						                                    <b-form-input
						                                    	v-model="detail.city"
						                                      	placeholder="City"
						                                      	:formatter="length50"
						                                      	:disabled="isDisabled"
						                                    ></b-form-input>
						                                    <small class="text-danger">{{ errors[0] }}</small>
						                                </validation-provider>
					                              	</b-form-group>
					                          	</b-col>
					                          	<b-col lg="12" class="px-0">             
					                              	<b-form-group label="Remarks" label-cols-lg="2">
					                                    <b-form-textarea
					                                    	v-model="detail.remarks"
				                                          	placeholder="Remarks"
				                                          	rows="5"
				                                          	:formatter="length1000"
				                                          	:disabled="isDisabled"
				                                        ></b-form-textarea>
					                              	</b-form-group>
					                          	</b-col>  
					                          	<b-col lg="12" class="px-0">
					                          		<div style="border-top: 1px solid #B1B1B1; height: 20px; "></div>  
					                          	</b-col>	         
					                      	</b-row>
					                    </validation-observer>
				                      	<b-row style="width: 100%; margin: 0;">
						        			<b-col lg="6" class="px-0">             
					                            <b-form-group label="Password" label-cols-lg="4" class="star-required input-group-label">
					                            	<validation-provider
						                                #default="{ errors }"
						                                rules="required|min:12"
						                                name="password"
						                                vid="password"
						                            >
						                                <b-form-input
						                                	v-model="form.password"
					                                      	placeholder="Password"
					                                      	type="password"
					                                      	:formatter="length50"
					                                      	:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col> 	 
				                          	<b-col lg="6" class="px-0">             
					                            <b-form-group label="Re-type Password" label-cols-lg="4" class="star-required input-group-label">
					                            	<validation-provider
						                                #default="{ errors }"
						                                name="Re-type Password"
						                                rules="required|confirmed:password"
						                            >
						                                <b-form-input
						                                	v-model="form.re_pass"
					                                      	placeholder="Retype Password"
					                                      	type="password"
					                                      	:disabled="isDisabled"
					                                    ></b-form-input>
					                                    <small class="text-danger">{{ errors[0] }}</small>
					                                </validation-provider>
					                            </b-form-group>
				                          	</b-col>      
						        		</b-row>
					                </div>
					            </b-form> 
			            </b-tab>  
			            <b-tab title="Activities">
			            	<Activities />
			            </b-tab>            
			        </b-tabs>     
			    </div>
			</b-col>
		</b-row>
	</section>
</template>
<script>

	import Breadcrumb from '../partial/Breadcrumb'

	import flatPickr from 'vue-flatpickr-component'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
    import { required, email, integer, min } from '@validations'
    import { togglePasswordVisibility } from '@core/mixins/ui/forms'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import Activities from './activities/List'
	import globalAdmin from '../model/globalAdmin'
	extend('min', {
    ...min,
    message: 'At least 12 characters and contain characters from at least two (2) of the following four (4) categories: Upper case (A through Z), Lower case (a through z), Digits (0-9), Special characters (!,$,#,%, etc)'
	});
	
	export default {
	  	components: {
	  		Breadcrumb,
          	vSelect,
          	ValidationProvider,
          	ValidationObserver,
          	flatPickr,

          	Activities
	  	},
	  	mixins: [togglePasswordVisibility,globalAdmin],
	  	data() {
		    return {
		    	userData: JSON.parse(localStorage.getItem('userData')),
				labels: { "participant-edit": "" },
				permission: [],
			    form: {
			    	password: null,
			    	re_pass: null,
			    },

			    selectStatus: [
			    	{title: 'Inactive', value: 0},
			    	{title: 'Active', value: 1},			    	
			    ],
			    selectedStatus: {
			    	title: null,
			   		value: null
			    },

			    salutation: [
                  { title: 'Mr', value: 0}, 
                  { title: 'Mrs', value: 1 },
                  { title: 'Miss', value: 2 },
                  { title: 'Ms', value: 3 },
				  { title: 'Sir', value: 4 },
				  { title: 'Dr', value: 5 },
                ],
                selectedSalutation: {
                	title: null,
                	value: null,
                },
                gender: [
                	{ title: 'Female', value: 0 },
                  	{ title: 'Male', value: 1 },                  
                ],
                selectedGender: {
                	title: null,
                	value: null,
                },

                selectCountry: [],
                selectedCountry: {},

                selectOr: [],
                selectedOr: {},    
				
				isDisabled: true,
                isEditing: true,
            	detail: [],
            	isDisabled: true,
            	disableField: 'disabled-field',	
            }
		},		
		computed: {
          	maxBirthdayFun(){            
              	var d = new Date()
              	d.setDate(d.getDate() - 6205)
              	return d
          	},
        },
        watch: {
			"$route": function(val) {
				if(val){
					this.featchItem()
					if(val.name == 'participant-edit'){
						this.isDisabled = false
						this.disableField = ''
					}
				}
			},
			'detail.first_name'(val){
	  			if(val){
	  				if(this.detail.last_name){
	  					this.detail.full_name = this.detail.last_name + ' ' + val
	  				}
	  				else{
	  					this.detail.full_name = val
	  				}	  				
	  			}
	  			else{
	  				if(this.detail.last_name){
	  					this.detail.full_name = this.detail.last_name
	  				}
	  				else{
	  					this.detail.full_name = ''
	  				}	  	
	  			}
	  		},
	  		'detail.last_name'(val){
	  			if(val){
	  				if(this.detail.first_name){
	  					this.detail.full_name = val + ' ' + this.detail.first_name
	  				}
	  				else{
	  					this.detail.full_name = val
	  				}
	  			}
	  			else{
	  				if(this.detail.first_name){
	  					this.detail.full_name = this.detail.first_name
	  				}
	  				else{
	  					this.detail.full_name = ''
	  				}
	  			}
	  		},
		},
		created(){
			this.permission = this.checkPermission(this.userData,"Participant Management");
			if(this.$route.name == 'participant-detail'){
				this.labels = {'participant-detail': ''}
			}else{
				this.isDisabled = false
			}
			if(this.$route && this.$route.name == 'participant-edit'){
				this.isDisabled = false
    			this.disableField = ''
			}
			this.featchItem()
			this.listOrgan()
			this.listAllCountry()
		},
		methods: {
			toEdit(id){
				this.isDisabled = false
				this.$router.push(`/admin/participant-management/edit/${id}`)
			},
			length1000(e){
		          return String(e).substring(0,1000)
		    },
		    length250(e){
		          return String(e).substring(0,250)
		    }, 
		    length100(e){
		          return String(e).substring(0,100)
		    },
		    length50(e){
		          return String(e).substring(0,50)
		    }, 
		    length20(e){
		          return String(e).substring(0,20)
		    }, 
			validateNumber(event) {
				const charCode = String.fromCharCode(event.keyCode);
				if (!/[0-9]/.test(charCode) && charCode && event.keyCode != 13 ) {
					event.preventDefault();
					this.isNumber = false;
				}else{
					this.isNumber = true;
				}
			},
			featchItem(){

	            this.$store
	            .dispatch('project/detailPart', {
	              	id: this.$route.params.id  
	            })
	            .then(response => {
	              	this.detail = Object.assign(response.data.data, response.data.data.infouser)
	              	this.labels['participant-edit'] = this.detail.full_name
	              	document.title = response.data.data.full_name + ' | Participant Management - ITEES | Your Global Partner of Choice'	

	              	this.labels['participant-detail'] = response.data.data.full_name

	              	if(this.detail.active == 0) {
						this.selectedStatus.title = 'Inactive'
						this.selectedStatus.value = 0					
					}
					if(this.detail.active == 1) {						
						this.selectedStatus.title = 'Active'			
						this.selectedStatus.value = 1			
					}
					this.selectedOr = this.detail.organisation

					if(this.detail.salutation == 0){
						this.selectedSalutation.title = 'Mr'
						this.selectedSalutation.value = 0
					}
					else if(this.detail.salutation == 1){
						this.selectedSalutation.title = 'Mrs'
						this.selectedSalutation.value = 1
					}
					else if(this.detail.salutation == 2){
						this.selectedSalutation.title = 'Miss'
						this.selectedSalutation.value = 2
					}
					else if(this.detail.salutation == 3){
						this.selectedSalutation.title = 'Ms'
						this.selectedSalutation.value = 3
					}
					else if(this.detail.salutation == 4){
						this.selectedSalutation.title = 'Sir'
						this.selectedSalutation.value = 4
					}
					else if(this.detail.salutation == 5){
						this.selectedSalutation.title = 'Dr'
						this.selectedSalutation.value = 5
					}

					if(this.detail.gender == 0){
						this.selectedGender.title = 'Female'
						this.selectedGender.value = 0
					}
					else if(this.detail.gender == 1){
						this.selectedGender.title = 'Male'
						this.selectedGender.value = 1
					}

					if(this.detail.country != null){
						this.getCountrybyId(this.detail.country)
					}
	            })
	            .catch((error) => {
	              console.log(error)
	            })
	        },
			listOrgan(){
	            this.$store
	            .dispatch('auth/Client_Organisation_List', {})
	            .then(response => {
	                  this.selectOr = response.data.data
	            })
	            .catch((error) => {
	                console.log(error)
	            })
	        },
	        getCountrybyId(id){
				this.$store
				.dispatch('auth/detailCountry', {
					id: id
				})
				.then(response => {
					this.selectedCountry = response.data.data
				})
				.catch((error) => {
					console.log(error)
				})
			},
	        listAllCountry(){
              	this.$store
              	.dispatch('country/fetchCountry')
              	.then(response => {
                  	this.selectCountry = response.data.data
              	})
              	.catch((error) => {
                	console.log(error)
              	})
          	},
			editAction(){
	          	this.isEditing = false
			},
			cancelAdd(){
				this.$router.push({ name: 'participant-management' })
			},
			saveAdd(){
				this.$refs.form_rel.validate().then(success => {
					if(success){
						let datas = {
							first_name: this.detail.first_name,
							last_name: this.detail.last_name,
							email: this.detail.email,
							password: this.form.password,
							confirm_password: this.form.re_pass,
							date_of_birth: this.detail.date_of_birth,
							organisation: this.selectedOr ? this.selectedOr.id : null,
							position: this.detail.position,
							streets: this.detail.streets,
							salutation: this.selectedSalutation ? this.selectedSalutation.value : null,
							gender: this.selectedGender ? this.selectedGender.value : null,
							country: this.selectedCountry ? this.selectedCountry.id : null,
							contact_no: this.detail.mobile,
							city: this.detail.city,
							postalcode: this.detail.postalcode,
							state_province: this.detail.state_province,
							status: this.selectedStatus ? this.selectedStatus.value : null,
							fullname: this.detail.full_name,
							remarks: this.detail.remarks,
						}
						// console.log('datas: ', datas)
						this.$store
			                .dispatch('project/editPart', {
			                	id: this.$route.params.id,
			                	data: datas
			                })
			                .then(response => {
			                    this.$toast({
			                        component: ToastificationContent,
			                        props: {
			                            title: this.detail.full_name + ' Updated!',
			                            variant: 'success',
			                        },
			                    },
			                    {
			                        position: 'top-center'
			                    })

			                	this.$router.push({ name: 'participant-management' })
			                	
			                })
			                .catch((error) => {
			                  	console.log(error)
			                  	this.$toast({
			                      	component: ToastificationContent,
			                      	props: {
			                        	title: error.response.data.message,
			                        	variant: 'danger',
			                      	},
			                    },
			                    {
			                      position: 'top-center'
			                    })
			                }) 
					}
					else{
		            	 this.$toast({
	                        component: ToastificationContent,
	                        props: {
	                          title: 'One or more fields have an error. Please check and try again.',
	                          variant: 'danger',
	                        },
	                      },
	                      {
	                        position: 'top-center'
	                      }
	                    )
		            }
				})
			}
		},
	};	

</script>

<style lang="scss">
	@import '@core/scss/vue/libs/vue-flatpicker.scss';
	@import "@/assets/scss/_ite_tab_table.scss";
</style>